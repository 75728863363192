import React from 'react';
import { Icon } from 'glints-aries/lib/@next';
import { get } from 'lodash';
import Link from 'nextDir/modules/Router/Link';
import { FormattedMessage } from 'react-intl';

import { CompanyStatus } from 'src/common/enums/companies';
import { useConfig } from 'src/common/hooks';
import { useResponsiveOpenTargetAttribute } from 'src/common/hooks/useResponsiveOpenTargetAttribute';
import { useSimilarCompanyLocation } from 'src/common/hooks/useSimilarCompanyLocation';
import {
  getCompanyLinkProps,
  getJobLinkProps,
  getJobWithCompanyFilterURL,
} from 'src/common/links';
import pushGTMEvent from 'src/common/utils/google-tag-manager';
import { CompactOpportunityCardTags } from 'src/components/Cards/CompactOpportunityCardTags';
import { Company } from 'src/global/models';
import { VerificationTierType } from 'src/global/models/Company';
import { useHostCandidateApp } from 'src/modules/CandidateMobileApp/Common/hooks/useHostCandidateApp';
import { TierVerificationBadge } from 'src/modules/Company/components/TierVerificationBadge';
import { UTM_REFERRER } from 'src/modules/Opportunities/constants';
import { JobInterface } from 'src/modules/Opportunity/interface';

import { JobCardSalary } from './JobCardSalary';
import * as Styled from './styles.sc';

export const CompanyCard: React.FC<
  React.PropsWithChildren<{
    company: Company;
    index: number;
  }>
> = ({ company, index }) => {
  const { isHostCandidate, hostQuery } = useHostCandidateApp();
  const { COUNTRY: country, LANG: language } = useConfig();
  const target = useResponsiveOpenTargetAttribute();
  const hostSearchQuery = isHostCandidate && hostQuery;

  const { id, logo, name } = company;

  const industryName = get(company, 'industry.name');
  const jobs = get(company, 'jobs', []) as JobInterface[];

  const activeJobCount = jobs.length;

  const companyLocation = useSimilarCompanyLocation(company);

  const verificationTierType = company?.verificationTier?.type;
  const isLegalDocumentVerified =
    verificationTierType === VerificationTierType.LEGAL_DOCUMENTS;
  const isCompanyVerified = company?.status === CompanyStatus.VERIFIED;
  const showBadge = isCompanyVerified && isLegalDocumentVerified;

  const onCardClick = () => {
    pushGTMEvent('glints/GoogleTagManager/SIMILAR_COMPANY_CARD_CLICKED', {
      payload: {
        company_name: name,
        number_of_active_jobs: activeJobCount,
      },
    });
  };

  return (
    <Styled.Anchor aria-label={`Company card ${index}`}>
      <Styled.Card>
        <Link href={getCompanyLinkProps(company)?.as}>
          <Styled.HeaderWrapper onClick={onCardClick}>
            <Styled.CardHeader>
              <Styled.CompanyLogo
                logo={logo}
                alt={name}
                sizes="60px"
                breakpoints={60}
              />
              <Styled.TextWrapper>
                <Styled.ShieldAndCompanyName>
                  <If condition={showBadge}>
                    <TierVerificationBadge
                      tierType={company?.verificationTier?.type}
                      isVIP={company?.isVIP}
                    />
                  </If>
                  <Styled.CompanyName aria-label={`Company card ${index} name`}>
                    {name}
                  </Styled.CompanyName>
                </Styled.ShieldAndCompanyName>

                <Styled.LocationName>{companyLocation}</Styled.LocationName>
              </Styled.TextWrapper>
            </Styled.CardHeader>

            {industryName && (
              <Styled.IconInfoRow>
                <Icon name="ri-building-fill" />
                <Styled.IndustryName>{industryName}</Styled.IndustryName>
              </Styled.IconInfoRow>
            )}

            <Styled.IconInfoRow>
              <Icon name="ri-briefcase-fill" />
              {activeJobCount ? (
                <object>
                  <Link
                    href={getJobWithCompanyFilterURL(
                      id,
                      name,
                      country,
                      language,
                      hostSearchQuery
                    )}
                    passHref={true}
                  >
                    <a aria-label={`Company card ${index} active jobs`}>
                      <FormattedMessage
                        id="company-card.job-count"
                        defaultMessage="{count} active {count, plural, one {job} other {jobs}}"
                        values={{ count: activeJobCount }}
                      />
                    </a>
                  </Link>
                </object>
              ) : (
                <FormattedMessage
                  id="company-card.no-job"
                  defaultMessage="No active job"
                />
              )}
            </Styled.IconInfoRow>
          </Styled.HeaderWrapper>
        </Link>

        <Styled.JobList>
          {jobs.slice(0, 2).map((job) => renderJob(job, target))}
          <a href={getCompanyLinkProps(company)?.as} onClick={onCardClick}>
            <Styled.CTAText>
              <FormattedMessage
                id="interactive-explore-more"
                defaultMessage="Explore More"
              />
            </Styled.CTAText>
          </a>
        </Styled.JobList>
      </Styled.Card>
    </Styled.Anchor>
  );
};

const renderJob = (job: JobInterface, target?: string) => {
  const jobLinkProps = getJobLinkProps(job, UTM_REFERRER.COMPANY_PROFILE);

  return (
    <Link key={job.id} {...jobLinkProps} passHref={true}>
      <a target={target} rel="noopener noreferrer">
        <Styled.JobCard key={job.id}>
          <Styled.JobHeader>
            <Styled.JobTitle>{job.title}</Styled.JobTitle>
            <JobCardSalary opportunity={job} />
          </Styled.JobHeader>
          <Styled.TagsWrapper>
            <CompactOpportunityCardTags
              maxCharacters={50}
              maxTags={5}
              opportunity={job}
            />
          </Styled.TagsWrapper>
        </Styled.JobCard>
      </a>
    </Link>
  );
};

import React, { useCallback, useRef, useState } from 'react';
import { PrimaryButton, Typography } from 'glints-aries/lib/@next';
import { Popover } from 'glints-aries/lib/@next/Popover';
import { Blue, Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { FormattedMessage, useIntl } from 'react-intl';

import { VerificationTierType } from 'src/global/models/Company';

import { getVerifiedArticalUrl } from '../TopFoldCompanySection/helper';
import * as S from './styles.sc';

interface Props {
  tierType?: VerificationTierType;
  isVIP?: boolean;
  isFromJobCard?: boolean;
}

export const TierVerificationBadge: React.FC<
  React.PropsWithChildren<Props>
> = ({ tierType, isVIP, isFromJobCard }) => {
  const intl = useIntl();
  const lang = intl.locale;
  const isLegalDocumentVerified =
    tierType === VerificationTierType.LEGAL_DOCUMENTS;
  const isProofOfOwnershipVerified =
    tierType === VerificationTierType.PROOF_OF_OWNERSHIP;
  const showDefaultIcon = !tierType && isFromJobCard;

  const [verifiedPopoverActive, setVerifiedPopoverActive] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const showPopover = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    setVerifiedPopoverActive(true);
  };

  const hidePopover = useCallback(() => {
    timeoutRef.current = setTimeout(() => {
      setVerifiedPopoverActive(false);
    }, 300);
  }, []);

  const cancelHidePopover = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
  };

  return (
    <Choose>
      <When condition={isProofOfOwnershipVerified || showDefaultIcon}>
        <S.Badge color={Neutral.B40}>
          <S.Icon name="ri-building-line" />
        </S.Badge>
      </When>
      <Otherwise>
        <Popover
          active={verifiedPopoverActive}
          preferredPosition="below"
          preferredAlignment="left"
          activator={
            <S.Badge
              color={Blue.S95}
              onClick={(e) => {
                e.stopPropagation();
                showPopover();
              }}
              onMouseEnter={showPopover}
              onMouseLeave={hidePopover}
            >
              <Choose>
                <When condition={isVIP && isLegalDocumentVerified}>
                  <S.ShieldBadge src="images/icons/vip-badge.svg" />
                </When>
                <When condition={isLegalDocumentVerified}>
                  <S.Icon name="ri-shield-check" />
                </When>
              </Choose>
            </S.Badge>
          }
          onClose={() => setVerifiedPopoverActive(false)}
        >
          <Popover.Pane>
            <S.PopoverContainer
              onMouseEnter={cancelHidePopover}
              onMouseLeave={hidePopover}
              onClick={(e) => e.stopPropagation()}
            >
              <S.PopoverTextContainer>
                <Typography as="span" variant="subtitle1">
                  <FormattedMessage
                    id="title-text-verified-badge"
                    defaultMessage="Verified Badge"
                  />
                </Typography>
                <Typography variant="body1">
                  <FormattedMessage
                    id="body-text-verified-badge"
                    defaultMessage="Glints has verified this company’s legal documents"
                  />
                </Typography>
              </S.PopoverTextContainer>
              <Choose>
                <When condition={isVIP && isLegalDocumentVerified}>
                  <S.VIPSection>
                    <S.VIPTag>
                      <FormattedMessage
                        id="text-premium-employer"
                        defaultMessage="Premium Employer"
                      />
                    </S.VIPTag>
                    <Typography
                      variant="body1"
                      color={Neutral.B18}
                      style={{ fontSize: 14 }}
                    >
                      <FormattedMessage
                        id="text-verified-company-enhance-hiring-process"
                        defaultMessage="This company uses Premium features to enhance their hiring process"
                      />
                    </Typography>
                  </S.VIPSection>
                </When>
              </Choose>

              <div>
                <PrimaryButton
                  onClick={() =>
                    window.open(getVerifiedArticalUrl(lang), '_blank')
                  }
                >
                  <FormattedMessage
                    id="text-learn-more"
                    defaultMessage="Learn More"
                  />
                </PrimaryButton>
              </div>
            </S.PopoverContainer>
          </Popover.Pane>
        </Popover>
      </Otherwise>
    </Choose>
  );
};

import { WorkArrangementOption } from 'src/global/models/Job';

type EntityLocale = {
  id: string;
  defaultMessage: string;
};

const localeRemoteMessage: EntityLocale = {
  id: 'label-remote',
  defaultMessage: 'Remote/WFH',
};
export const localeOnsiteMessage: EntityLocale = {
  id: 'label-on-site',
  defaultMessage: 'On-site',
};
const localeHybridMessage: EntityLocale = {
  id: 'label-hybrid',
  defaultMessage: 'Hybrid',
};

export const localeConfidentialCompany: EntityLocale = {
  id: 'job_details_page.company.name',
  defaultMessage: 'Confidential Company',
};

export const localeSimilarJobForYou: EntityLocale = {
  id: 'job_detail_page.similar_jobs_header',
  defaultMessage: 'Similar jobs for you',
};

export const localeJobDetailPostedAt: EntityLocale = {
  id: 'job_detail_page.posted',
  defaultMessage: 'Posted',
};

export const localeJobDetailPageUpdated: EntityLocale = {
  id: `job_detail_page.updated`,
  defaultMessage: `Updated`,
};

export const localeTitleTextSafetyTips: EntityLocale = {
  id: 'title-text-safety-tips',
  defaultMessage: 'Glints Safety Tips',
};

export const localeBodyTextSafetyTips: EntityLocale = {
  id: 'body-text-safety-tips',
  defaultMessage: `Legitimate employers won’t ask for contact Telegram or any kind of top-ups or payment. Do not provide your messaging app contacts, bank details, or credit card information.`,
};

export const localeGlintsxBannerButton: EntityLocale = {
  id: 'glintsx.banner.button',
  defaultMessage: 'Learn More',
};

export const localeJobDetailPageJobReport: EntityLocale = {
  id: 'job_detail_page.job.report',
  defaultMessage: 'Report This Job',
};

export const localeTextGetRealTimeUpdates: EntityLocale = {
  id: `text-get-real-time-updates`,
  defaultMessage: `Get real-time updates only on the Glints App`,
};

export const localeTextScanTheQrCodeToDownload: EntityLocale = {
  id: `text-scan-the-qr-code-to-download`,
  defaultMessage: `Scan the QR code to download`,
};

export const localeOpportunityMetadataRemoteMessage: EntityLocale = {
  id: `opportunity.metadata.remote-message`,
  defaultMessage: `Remote Jobs. `,
};

export const localeOpportunityMetadataDescription: EntityLocale = {
  id: `opportunity.metadata.description`,
  defaultMessage: `Apply for {jobTitle} at {companyName}. {remoteMessage}{experienceMessage}{jobType}, Job Location: {jobLocation}`,
};

export const localeOpportunityMetadataDescriptionNoCompany: EntityLocale = {
  id: `opportunity.metadata.description-no_company`,
  defaultMessage: `Glints' partner (Confidential Company) is hiring {jobType} {jobTitle} in {jobLocation}. {remoteMessage}{experienceMessage}See more details and apply today!`,
};

export const localeOpportunityMetadataTitle: EntityLocale = {
  id: `opportunity.metadata.title`,
  defaultMessage: `{jobTitle} Jobs at {companyName}, {jobLocation} {jobClosed}| Glints`,
};

export const localeOpportunityMetadataExperienceMessage: EntityLocale = {
  id: `opportunity.metadata.experience-message`,
  defaultMessage: `{minYearsOfExperience} - {maxYearsOfExperience} years of experience are required. `,
};

export const localeOpportunityMetadataTitleNoCompany: EntityLocale = {
  id: `opportunity.metadata.title-no_company`,
  defaultMessage: `Recruiting {jobTitle} (Confidential Company) in {jobLocation} {jobClosed}| Glints`,
};

export const localeJobDetailPageRelevantJobsHeader: EntityLocale = {
  id: `job_detail_page.relevant_jobs_header`,
  defaultMessage: `Other Jobs from {companyName}`,
};

export class OpportunityLocaleKeysHelper {
  static getWorkArrangementOptionLocale = (
    workArrangementOption?: WorkArrangementOption
  ): EntityLocale => {
    switch (workArrangementOption) {
      case WorkArrangementOption.ONSITE:
        return localeOnsiteMessage;
      case WorkArrangementOption.REMOTE:
        return localeRemoteMessage;
      case WorkArrangementOption.HYBRID:
        return localeHybridMessage;
      default:
        return localeOnsiteMessage;
    }
  };
}

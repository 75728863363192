import { RawDraftContentState } from 'draft-js';
import { defineMessages } from 'react-intl';

import { CompanyStatus } from 'src/common/enums/companies';
import { HierarchicalLocation } from 'src/modules/Profile/graphql/hierarchicalLocations';

import { ResourceLinks } from './ResourceLinks';

export enum ECompanySize {
  SELF_EMPLOYED = 'SELF_EMPLOYED',
  BETWEEN_1_AND_10 = 'BETWEEN_1_AND_10',
  BETWEEN_11_AND_50 = 'BETWEEN_11_AND_50',
  BETWEEN_51_AND_200 = 'BETWEEN_51_AND_200',
  BETWEEN_201_AND_500 = 'BETWEEN_201_AND_500',
  BETWEEN_501_AND_1000 = 'BETWEEN_501_AND_1000',
  BETWEEN_1001_AND_5000 = 'BETWEEN_1001_AND_5000',
  BETWEEN_5001_AND_10000 = 'BETWEEN_5001_AND_10000',
  MORE_THAN_10000 = 'MORE_THAN_10000',
}

export enum VerificationTierType {
  PROOF_OF_OWNERSHIP = 'PROOF_OF_OWNERSHIP',
  LEGAL_DOCUMENTS = 'LEGAL_DOCUMENTS',
}

export const companySizeMap = defineMessages({
  [ECompanySize.SELF_EMPLOYED]: {
    id: 'company-size.self-employed',
    defaultMessage: 'Self employed',
  },
  [ECompanySize.BETWEEN_1_AND_10]: {
    id: 'company-size.one-to-ten',
    defaultMessage: '1 - 10 employees',
  },
  [ECompanySize.BETWEEN_11_AND_50]: {
    id: 'company-size.eleven-to-fifty',
    defaultMessage: '11 - 50 employees',
  },
  [ECompanySize.BETWEEN_51_AND_200]: {
    id: 'company-size.fifty-one-to-two-hundred',
    defaultMessage: '51 - 200 employees',
  },
  [ECompanySize.BETWEEN_201_AND_500]: {
    id: 'company-size.two-hundred-one-to-five-hundred',
    defaultMessage: '201 - 500 employees',
  },
  [ECompanySize.BETWEEN_501_AND_1000]: {
    id: 'company-size.five-hundred-one-to-one-thousand',
    defaultMessage: '501 - 1000 employees',
  },
  [ECompanySize.BETWEEN_1001_AND_5000]: {
    id: 'company-size.one-thousand-one-to-five-thousand',
    defaultMessage: '1001 - 5000 employees',
  },
  [ECompanySize.BETWEEN_5001_AND_10000]: {
    id: 'company-size.five-thousand-one-to-ten-thousand',
    defaultMessage: '5001 - 10,000 employees',
  },
  [ECompanySize.MORE_THAN_10000]: {
    id: 'company-size.more-than-ten-thousand',
    defaultMessage: '> 10,000 employees',
  },
});

export interface Industry {
  id: string;
  name: string;
}

export interface LocationFormattedName {
  formattedName: string;
  locationId: string;
}

export interface VerificationTier {
  type?: VerificationTierType;
}

export interface Company extends ResourceLinks {
  website: string;
  socialMediaSites: {
    facebook?: string;
    instagram?: string;
    linkedin?: string;
    twitter?: string;
  };
  id: string;
  name: string;
  status: CompanyStatus;
  logo: string;
  bannerPic: string;
  tagline: string;
  video: string;
  planValidUntil: string;
  isVerified: boolean;
  isHiring: boolean;
  stripeCustomerId: string;
  localOffice: boolean;
  customAttributes: any;
  photos: any;
  registrationCode: any;
  descriptionRaw: any;
  CityId: number;
  IndustryId: number;
  industry?: Industry;
  descriptionJsonString?: string;
  cultureJsonString?: string;
  socialMediaSitesJsonString?: string;
  photosJsonString?: string;
  PlanId: string;
  createdAt: string;
  updatedAt: string;
  size: ECompanySize;
  address?: string;
  LocationId?: string;
  hierarchicalLocation?: HierarchicalLocation;
  cultureRaw?: RawDraftContentState;
  reviewedAt?: string;
  isVIP?: boolean;
  verifiedAt?: number;
  locationFormattedNames?: Array<LocationFormattedName>;
  verificationTier?: VerificationTier;
}

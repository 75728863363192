import { Tag as GeneralTag } from 'glints-aries';
import { Tag as AriesTag } from 'glints-aries/lib/@next';
import { Orange } from 'glints-aries/lib/@next/utilities/colors';
import styled from 'styled-components';

export const Tag = styled(AriesTag)`
  max-width: 150px;
  span {
    width: 100%;
    div {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      span {
        display: block;
        max-width: 150px;
      }
    }
  }
`;

export const VIPTag = styled(GeneralTag)`
  width: fit-content;
  border-radius: 4px;
  border: none;
  background-color: ${Orange.S21};
  label {
    color: ${Orange.S100};
  }
  & .tag-content {
    padding: 4px 8px;
  }
`;

import { ScreenSize, Tag } from 'glints-aries';
import { Icon as AriesIcon } from 'glints-aries/lib/@next';
import { Neutral, Orange } from 'glints-aries/lib/@next/utilities/colors';
import styled from 'styled-components';

export const Badge = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    width: 24px;
    height: 24px;
    fill: ${({ color }) => color};
  }

  @media (max-width: ${ScreenSize.tablet}px) {
    width: 20px;
    height: 20px;
  }
`;

export const ShieldBadge = styled.img`
  width: 24px;
  height: 24px;

  @media (max-width: ${ScreenSize.tablet}px) {
    width: 20px;
    height: 20px;
  }
`;

export const Icon = styled(AriesIcon)`
  @media (max-width: ${ScreenSize.tablet}px) {
    width: 20px;
    height: 20px;
  }
`;

export const PopoverContainer = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const PopoverTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const PopoverVIPContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const VIPSection = styled.div`
  padding: 12px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: ${Neutral.B99};
`;

export const VIPTag = styled(Tag)`
  width: fit-content;
  border-radius: 4px;
  border: none;
  background-color: ${Orange.S21};
  label {
    color: ${Orange.S100};
  }
  & .tag-content {
    padding: 4px 8px;
  }
`;
